import { useEffect, useState } from "react";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import methodModel from "../../methods/methods";
import moment from "moment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  ConnectSocket,
  SocketURL,
} from "../../components/global/SocketNetwork/ConnectSocket";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import platformFeeConvertor from "../CommonMethods/platformFeeConvertor";
import environment from "../../environment";

export default function CreateContract({
  HandleClose,
  update,
  isTrue,
  projectid = "",
  userid = "",
  projectDescription = "",
  room_id = "",
  chatwithId = "",
  ComeFrom = "",
  hourlyRate = null,
  reduxuserId = "",
}) {
  const PlatformData = useSelector((state) => state.PlatformfeeReducer);
  const [form, setform] = useState({
    projectid: "",
    weeklyLimit: "",
    hourlyRate: hourlyRate,
    employerHourlyRate: Number(methodModel.CalculatePercentAmount(
      hourlyRate,
      PlatformData.platformFee
    ) + Number(hourlyRate)),
    paymentOption: "weekly",
    startDate: "",
    hireMore: "",
    accountMangerId: "",
    directmanagerId: "",
  });
  useEffect(() => {
    setform(prev => ({
      ...prev, employerHourlyRate: Number(methodModel.CalculatePercentAmount(
        hourlyRate,
        PlatformData.platformFee
      ) + Number(hourlyRate)),
    }))
  }, [hourlyRate])

  const user = useSelector((state) => state.user);
  const [SelectedPolicies, setSelectedPolicies] = useState(user.policy);

  const Navigate = useHistory();
  const [JobPosts, setJobPosts] = useState([]);
  const GetJobPosts = () => {
    loader(true);
    const myuserid =
      user.id && user.id != "" && user.id != undefined ? user.id : reduxuserId;
    ApiClient.get(`projects?status=active&userId=${myuserid}`).then((res) => {
      if (res.success) {
        setJobPosts(res.data);
        if (projectid) {
          let data = res?.data?.find((item) => item?._id === projectid);
          if (data) {
            setform(prev => ({ ...prev, contractName: data?.title || data?.name }))
          }
        }
      }
      loader(false);
    });
  };
  useEffect(() => {
    setform({
      projectid: "",
      weeklyLimit: "",
      hourlyRate: hourlyRate,
      paymentOption: "",
      startDate: "",
      employerHourlyRate: Number(methodModel.CalculatePercentAmount(
        hourlyRate,
        PlatformData.platformFee
      ) + Number(hourlyRate))
    });
    GetJobPosts();
    if (isTrue == true) {
      setform({
        projectid: projectid,
        weeklyLimit: "",
        hourlyRate: hourlyRate,
        paymentOption: "",
        startDate: "",
        employerHourlyRate: Number(methodModel.CalculatePercentAmount(
          hourlyRate,
          PlatformData.platformFee
        ) + Number(hourlyRate))
      });
    }
  }, [update]);

  const [ProjectDescription, setProjectDescription] = useState("-----------");
  const SetProjectDescriptionFunc = () => {
    JobPosts.filter((item) => {
      if (item.id == form.projectid) {
        setProjectDescription(item.description);
      }
    });
  };

  const HandleAddContract = (e) => {
    e.preventDefault();
    let method = "post";
    let url = "";
    loader(true);
    const payload = {
      contractName: form?.contractName,
      contractTo: userid,
      projectId: form.projectid,
      weeklyLimit: form.weeklyLimit,
      hourlyRate: Number(form?.employerHourlyRate) - methodModel.CalculatePercentAmount(
        form?.employerHourlyRate,
        PlatformData.platformFee
      ),
      paymentOption: form.paymentOption,
      startDate: form.startDate,
      employerHourlyRate: Number(form?.employerHourlyRate),
      hireMore: form.hireMore,
      policy: SelectedPolicies,
      timeZone: user.globallyTimeZone
        ? user.globallyTimeZone
        : user.timeZone
          ? user.timeZone
          : "",
      timeTable:
        user?.timeTable && user?.timeTable.length > 0
          ? user.timeTable
          : environment.defaultSchedule,
      accountMangerId: form.accountMangerId,
      directmanagerId: form.directmanagerId,
    };
    ApiClient.post(`contract`, payload).then((res) => {
      if (res.success) {
        ConnectSocket.emit(`unread-noti-count`, { user_id: user.id });
        const ContractId = res.data && res?.data?.id;
        toast.success("Offer sent successfully");
        if (ComeFrom && ComeFrom == "mainhire") {
          HandleMessageClick(ContractId);
        } else {
          const AnotherMessagePayload = {
            room_id: room_id,
            sender: user.id,
            type: "TEXT",
            content: projectDescription,
            message_type: "offer",
            project_id: form.projectid,
            inviteId: ContractId,
          };
          ConnectSocket.emit(`send-message`, AnotherMessagePayload);
        }
        HandleClose();
        // Step 1: Create the button element
        const button = document.createElement('button');

        // Step 2: Set button attributes and add event listener
        button.id = 'openLinkButton';
        button.innerHTML = 'Open Link'; // This is the button text (optional)

        button.addEventListener('click', function () {
          const url = res.data && res.data.sign_url;
          window.open(url, '_blank'); // Opens the URL in a new tab
        });

        // Step 3: Append the button to the body (necessary for the click event to work in some cases)
        document.body.appendChild(button);

        // Programmatically trigger the click
        button.click();

        // Optional: Remove the button after the click to clean up the DOM
        document.body.removeChild(button);

        // setTimeout(() => {
        //   window.open(, "_parent");
        // }, 300);        

        Navigate.push(`/offer/${ContractId}`);
      }

      loader(false);
    });
  };

  const HandleMessageClick = (inviteId) => {
    loader(true);
    const payload = {
      addedBy: user.id,
      friendId: chatwithId,
      projectId: form.projectid,
    };
    ApiClient.post("addUserFriend", payload).then((res) => {
      axios
        .post(SocketURL + "chat/user/join-group", {
          chat_by: user.id,
          chat_with: chatwithId,
          project_id: form.projectid,
        })
        .then((newres) => {
          const data = newres.data && newres.data.data;
          const AnotherMessagePayload = {
            room_id: data.room_id,
            sender: user.id,
            type: "TEXT",
            content: ProjectDescription,
            message_type: "offer",
            project_id: form.projectid,
            inviteId: inviteId,
          };
          ConnectSocket.emit(`unread-noti-count`, { user_id: chatwithId });
          ConnectSocket.emit(`send-message`, AnotherMessagePayload);
        });

      loader(false);
    });
  };

  useEffect(() => {
    SetProjectDescriptionFunc();
  }, [form.projectid]);

  const AllPlolicies = [
    { type: "Time Off Policy" },
    { type: "Sick Time Policy" },
    { type: "Holiday Policy" },
  ];

  const HandleSelectPolicyChange = (e) => {
    let initialValues = SelectedPolicies;
    const { name, checked } = e.target;
    if (checked) {
      initialValues.push(name);
    } else {
      initialValues = initialValues.filter((item) => item != name);
    }
    setSelectedPolicies(initialValues);
  };

  //  FOR   GETTING THE DIRECT MANAGER LIST
  const [DirectManagers, setDirectManagers] = useState([]);
  const GetDirectManagers = () => {
    ApiClient.get(
      `staff/list?userId=${user.id}&roleId=${environment.DirectManagerId}`
    ).then((res) => {
      if (res.success) {
        setDirectManagers(res?.data);
      }
    });
  };
  // FOR GETTING THE ACCOUNT MANAGER LIST
  const [AccountManagers, setAccountManagers] = useState([]);
  const GetAccountManagers = () => {
    ApiClient.get(
      `staff/list?userId=${user.id}&roleId=${environment.AccountManagerId}`
    ).then((res) => {
      if (res.success) {
        setAccountManagers(res?.data);
      }
    });
  };
  useEffect(() => {
    GetDirectManagers();
    GetAccountManagers();
  }, []);

  const handleJobPost = (data) => {
    const jobPostName = data ? JobPosts?.find((item) => item?.id == data)?.title : ""
    setform({ ...form, projectid: data, contractName: jobPostName })
  }

  return (
    <>
      <div className="">
        <form className=" " onSubmit={(e) => HandleAddContract(e)}>

          <div className="row">
            <div className="col-md-12 mt-3">
              <div className="form-group select_optionlimit mb-0">
                <label className="">
                  Job Post <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control text-capitalize "
                  disabled={isTrue}
                  value={form.projectid}
                  onChange={(e) => handleJobPost(e.target.value)}
                  required
                >
                  <option className="limit-text" value="">
                    Please Select a Job
                  </option>
                  {JobPosts.map((item, index) => (
                    <option
                      className="text-capitalize limit-text"
                      disabled={isTrue}
                      value={item.id}
                    >
                      {item?.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <label c>
                Contract Title<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                required
                className="form-control"
                value={form.contractName}
                onChange={(e) =>
                  setform({ ...form, contractName: e.target.value })
                }
              />
            </div>

            <div className="col-md-6 mt-3">
              <label>
                Hourly Rate <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                step="0.01"
                required
                className="form-control"
                min={0}
                value={form?.employerHourlyRate}
                // platformFeeConvertor(hourlyRate)
                onChange={(e) =>
                  setform({ ...form, employerHourlyRate: e.target.value })
                }
              // onKeyPress={(e) => methodModel.InputNumber(e)}
              />
            </div>
            <div className="col-md-6 mt-3">
              <label c>
                Weekly Hours Limit<span className="text-danger">*</span>
              </label>
              <input
                type="number"
                min={0}
                required
                className="form-control"
                value={form.weeklyLimit}
                onChange={(e) =>
                  setform({ ...form, weeklyLimit: e.target.value })
                }
                onKeyPress={(e) => methodModel.InputNumber(e)}
              />
            </div>
            <div className="col-md-12 mt-3">
              <label>
                Start Date <span className="text-danger">*</span>
              </label>
              <input
                type="date"
                min={moment(new Date()).format("MM-DD-YYYY")}
                required
                className="form-control w-100"
                value={form.startDate}
                onChange={(e) =>
                  setform({ ...form, startDate: e.target.value })
                }
              />
            </div>


            <div className="col-md-12 mt-3">
              <label>
                Hire More <span className="text-danger">*</span>
              </label>
              <select
                className="form-control"
                value={form.hireMore}
                onChange={(e) => setform({ ...form, hireMore: e.target.value })}
                required
              >
                <option value="">Please Select</option>
                <option value="true">
                  Looking More Virtual assistant for this job
                </option>
                <option value="false">I am done with this Job</option>
              </select>
            </div>
            {DirectManagers?.length != 0 ? <div className="col-md-6 mt-3">
              <label>Direct Manager </label>
              <select
                className="form-control"
                value={form.directmanagerId}
                onChange={(e) =>
                  setform({ ...form, directmanagerId: e.target.value })
                }
              >
                <option value="">Please Select</option>
                {DirectManagers.map((item) => (
                  <option value={item.id}>{item.fullName}</option>
                ))}
              </select>
            </div> :
              <div className="col-md-6 mt-3">
                <label>Direct Manager </label>
                <select
                  className="form-control"

                >
                  <option value="">Please Select</option>
                  <option >{user?.fullName}</option>
                </select>
              </div>}

            {DirectManagers?.length != 0 ?
              <div className="col-md-6 mt-3">
                <label>Accounting contact </label>
                <select
                  className="form-control"
                  value={form.accountMangerId}
                  onChange={(e) =>
                    setform({ ...form, accountMangerId: e.target.value })
                  }
                >
                  <option value="">Please Select</option>
                  {AccountManagers.map((item) => (
                    <option value={item.id}>{item.fullName}</option>
                  ))}
                </select>
              </div> :

              <div className="col-md-6 mt-3">
                <label>Accounting contact </label>
                <select
                  className="form-control"

                >
                  <option value="">Please Select</option>
                  <option >{user?.fullName}</option>
                </select>
              </div>}

            {/* <div className="col-md-6 mt-3">
              <label>Accounting contact </label>
              <select
                className="form-control"
                value={form.accountMangerId}
                onChange={(e) =>
                  setform({ ...form, accountMangerId: e.target.value })
                }
              >
                <option value="">Please Select</option>
                {AccountManagers.map((item) => (
                  <option value={item.id}>{item.fullName}</option>
                ))}
              </select>
            </div> */}

            {/* <div className='col-md-12 mt-3 ml-3'>
    
    <input type='checkbox' value={form.manuallyTime} onChange={e=>setform({...form,manuallyTime:e.target.checked})}/><span className='ml-2'>Allow Virtual Assistant to Log time Manually</span>
 </div> */}
          </div>
          <div className="mt-4 text-right" style={{ float: "right" }}>
            <button
              className="btn btn-secondary mr-2"
              type="button"
              onClick={(e) => HandleClose(e)}
            >
              Close
            </button>
            <button className="btn btn-primary" type="submit">
              Save
            </button>
          </div>
        </form >
      </div >
    </>
  );
}
