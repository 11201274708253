/*
 * @Author: Ghost
 * @Email: Ghost@yopmail.com
 * @Date: 2024-02-13 11:30:23
 * @Last Modified by: Someone
 * Email: anilgodara4239@gmail.com
 * @Last Modified time: 2024-03-21 11:07:36
 * */
import { useState, useEffect } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import "./home.scss";
import { useSelector } from "react-redux";
import methodModel from "../../methods/methods";
import Header from "../../components/global/header";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import CreateContract from "../CreateContractPages";
import platformFeeConvertor from "../CommonMethods/platformFeeConvertor";
import { ConnectSocket } from "../../components/global/SocketNetwork/ConnectSocket";
import Select from "react-select";
import environment from "../../environment";
import parse from 'html-react-parser'

const VirtualDetail = () => {
  function validateYouTubeUrl(url) {
    // var url = $('#youTubeUrl').val();
    if ((url != undefined || url != '') && url) {
      var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      var match = url?.match(regExp);
      if (match && match[2].length == 11) {
        return 'https://www.youtube.com/embed/' + match[2]
      }
      else {
        return ""
        // Do anything for not being valid
      }
    }
    else {
      return false
    }
  }
  const { id } = useParams();
  const params = new URLSearchParams(window.location.search);
  const PlatformData = useSelector((state) => state.PlatformfeeReducer);
  const Projectid = params.get("projectid");
  useEffect(() => {
    if (Projectid) {
      setSelectedProject(`${Projectid}`);
      setMakeitDisable(true);
    }
  }, [Projectid]);
  const Navigate = useHistory();
  const user = useSelector((state) => state.user);
  const [UserData, setUserdata] = useState({});
  const [Descriptiondata, setDescriptiondata] = useState("");
  const GetDetails = () => {
    loader(true);
    ApiClient.get(`user/details?id=${id}`).then((res) => {
      if (res.success) {
        setUserdata(res.data);
      }
      loader(false);
    });
  };
  useEffect(() => {
    GetDetails();
  }, []);

  const [ProjectData, setProjectData] = useState([]);

  const [IsInvitedFirst, setIsInvitedFirst] = useState(false);
  const [EveryJobInvited, setEveryJobInvited] = useState(false);
  const GetProjects = () => {
    loader(true);
    ApiClient.get(`projects?userId=${user.id}&InviteFreelancerId=${id}&hireMore=true&isFrontend=true`).then(
      (res) => {
        if (res.success) {
          const newdata = res.data;
          const data = [];
          res.data?.map((item) => {
            if (!item?.isJobInvited)
              data.push({
                value: item?.id || item?._id,
                label: item?.name || item?.title,
              });
          });
          setProjectData(data);
          const answer = newdata.some((item) => item.isJobInvited == true);
          setIsInvitedFirst(answer);
          const everyinvited = newdata.every(
            (item) => item.isJobInvited == true
          );
          setEveryJobInvited(everyinvited);
        }
        loader(false);
      }
    );
  };

  useEffect(() => {
    if (user?.id) {
      GetProjects();
    }
  }, []);

  const [SelectedProject, setSelectedProject] = useState(null);
  const [MakeitDisable, setMakeitDisable] = useState(false);

  const HandleSubmit = (e) => {
    e.preventDefault();
    loader(true);
    ApiClient.post("invite", {
      freelancerId: id,
      projectId: SelectedProject?.value,
      description: Descriptiondata,
    }).then((res) => {
      if (res.success) {
        document.getElementById("closeinviteModal").click();
        setSelectedProject("");
        setDescriptiondata("");
        GetProjects();
        ConnectSocket.emit(`unread-noti-count`, { user_id: id });
        toast.success("Virtual Assistant Invited Successfully");
      }
      loader(false);
    });
  };

  const HandleCloseModal = () => {
    document.getElementById("closecont").click();
  };

  const [AnalyticsData, setAnalyticsData] = useState({});
  const GetAnalystics = () => {
    ApiClient.get(`userAnalytic?id=${id}`).then((res) => {
      if (res.success) {
        setAnalyticsData(res.data);
      }
    });
  };
  useEffect(() => {
    GetAnalystics();
  }, []);



  const [EducationData, setEducationData] = useState([]);
  const GetEducation = () => {
    loader(true);
    ApiClient.get(`educations?userId=${id}`).then(res => {
      if (res.success) {
        setEducationData(res.data);
      }
    })
  }
  const [EmployeementData, setEmoloyeementData] = useState([]);
  const GetEmployementData = () => {
    ApiClient.get(`employments?userId=${id}`).then(res => {
      if (res.success) {
        setEmoloyeementData(res.data)
      }
    })
  }
  useEffect(() => {
    GetEducation();
    GetEmployementData();
  }, [])
  return (
    <>
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-md-12 mt-4 mb-4">
            <div className="row col-md-reverse">
              <div className="d-flex align-items-start col-md-6">
                <img
                  src={`${methodModel.userImg(UserData.image)} `}
                  className="virtual_img shadow"
                />
                <div className="ml-4">
                  <h2 className="name_virtual text-capitalize">
                    {UserData.fullName}
                  </h2>
                  <p>
                    <p>
                      {methodModel.generateStarRating(
                        Number(UserData.totalRating)
                      )}
                    </p>
                    <span className="material-icons location">location_on</span>{" "}
                    {/* {UserData.address} - */}
                    {UserData.state}, {UserData.country}
                    {/* {UserData.timeZone && UserData.timeZone.label} */}
                  </p>
                  {/* <p className='mt-3'>${UserData.hourlyRate}</p> */}
                  {UserData.hardSkills &&
                    UserData.hardSkills.map((item) => (
                      <span className="mx-1 text-capitalize">{item?.name}</span>
                    ))}
                </div>
              </div>
              <div className="mb-2 col-lg-6 text-sm-right">
                <button
                  className="back btn btn-secondary mr-2 mb-2 phTop"
                  onClick={(e) => Navigate.goBack()}
                >
                  Back
                </button>
                {user?.id ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary mb-2 mr-2 phTop"
                      data-toggle="modal"
                      data-target="#opencont"
                    >
                      Hire
                    </button>
                    <button
                      type="button"
                      disabled={IsInvitedFirst}
                      className="btn btn-success mb-2 phTop"
                      onClick={(e) => {
                        Projectid ? "" : setSelectedProject("");
                      }}
                      data-toggle="modal"
                      data-target="#openmodal"
                    >
                      {IsInvitedFirst ? "Invited" : "Invite"}
                    </button>
                    {IsInvitedFirst && !EveryJobInvited ? (
                      <button
                        type="button"
                        className="btn btn_info mb-2 ml-2 phTop"
                        onClick={(e) => {
                          setMakeitDisable(false);
                          Projectid ? "" : setSelectedProject("");
                        }}
                        data-toggle="modal"
                        data-target="#openmodal"
                      >
                        Another Invite
                      </button>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-lg-4 ">
            {/* <div className='p-3'>
            <b>About</b>
            <p className='mt-2'>{UserData.description}</p>
            </div> */}
            {/* <hr /> */}
            <div className="border_total_sale mt-3">
              {UserData.softSkills?.length > 0 ? (
                <>
                  <div className="p-3">
                    <h6>Other Skills</h6>
                    {UserData.softSkills?.map((item) => (
                      <span className="mx-1 text-capitalize">{item?.name}</span>
                    ))}
                  </div>
                  <hr />
                </>
              ) : null}
              <div className="p-3">
                <div className="d-flex justify-content-between">
                  <div className="total_data">
                    <h5>
                      {methodModel.formatNumbersinPremium(
                        parseInt(AnalyticsData?.totalEarning)
                      )}
                      + USD
                    </h5>
                    <p>Total Earnings</p>
                  </div>
                  <div className="total_data">
                    <h5>{AnalyticsData?.totalContract}</h5>
                    <p>Total jobs</p>
                  </div>
                  <div className="total_data">
                    <h5>
                      {parseFloat(AnalyticsData?.totalbilling).toFixed(2)}
                    </h5>
                    <p>Total hours</p>
                  </div>
                </div>
              </div>

              <hr />
              <div className="p-3">
                <h6 className="font-weight-bold mb-2">Hourly Charge</h6>
                <p className="mb-0">
                  {/* More then{" "} */}
                  {parseFloat(
                    methodModel.CalculatePercentAmount(
                      UserData.hourlyRate,
                      PlatformData.platformFee
                    ) + parseInt(UserData.hourlyRate)
                  ).toFixed(2)}{" "}
                  USD / Hrs
                </p>
                <p> 24 hrs response time</p>
              </div>
            </div>
          </div>
          <div className="col-lg-8 ">
            <div className="border-top border-bottom p-3 border-right border-left mb-5 mt-3">
              <div className="d-flex justify-content-between alignitems-center">
                <h4 className="font-weight-bold mr-4">
                  {UserData.skillType &&
                    UserData.skillType?.map((item) => {
                      return <span className="mx-1 text-capitalize">{item?.name}</span>;
                    })}
                </h4>
                <h5>
                  <b>{platformFeeConvertor(UserData.hourlyRate)} USD /hr</b>
                </h5>
              </div>
              <p className="mt-5 desc_font">{UserData.description} </p>
            </div>
          </div>




          <div className='col-md-12 mt-md-3'>
   
            <div >
             <div className="add-border mt-md-3 p-0">
             <label className="profileheddingcls profileheddingclsfont px-3 pt-3 pb-2">Resume</label>
             <hr  className="mt-0"/>
            
           
            <div className='profiledetailscls p-3'>{parse(`${UserData.resume}`)}</div>
             </div>

<div className=" add-border mt-3 p-0">
<label className="profileheddingcls profileheddingclsfont px-3 pt-3 pb-2"> Resume Document</label>
<hr  className="mt-0"/>
              <div className='profiledetailscls mb-3 p-3'>{UserData?.resumeDocument != "" ?
                <img style={{ cursor: "pointer" }} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVlvvKw31TIIrVV3C6ZVXErB8sDduDYcyAFg&usqp=CAU' onClick={e => window.open(`${environment.api}images/document/${UserData.resumeDocument}`)} className="doument-img" /> : null
              }</div>
</div>
             

            </div>

         

            <div className='col-md-12 border_total_sale mt-3'>
              {UserData?.portfolioUrl != "" ? (
                <div className="col-md-12 mb-3 p-0">
                  <label className="profileheddingcls profileheddingclsfont px-3 pt-3 pb-2">PortfolioUrl</label>
                  <hr  className="mt-0"/>
                  <div style={{ cursor: 'pointer' }} className='profiledetailscls text-info p-3 word-break' onClick={() => window.open(UserData?.portfolioUrl, '_blank')}>{UserData?.portfolioUrl}</div>
                </div>) 
                 
                : (
                ""
              )}
                 </div>
<div className='col-md-12 border_total_sale mt-3'>
              {UserData?.portfolioImage && UserData?.portfolioImage?.length > 0 ? <div className="col-md-12 mb-3   p-0 ">
                <label className="profileheddingcls profileheddingclsfont  px-3 pt-3 pb-2 ">Portfolio Document</label>
                <hr  className="mt-0"/>
                <div className='profiledetailscls p-3'>{UserData?.portfolioImage && UserData?.portfolioImage?.map((item, index) => (
                  <img style={{ cursor: "pointer" }} src='https://cdn1.iconfinder.com/data/icons/human-resources-33/100/hr-06-512.png' onClick={e => window.open(`${environment.api}images/users/${item}`)} width={40} height={40} />
                ))}</div>
              </div> : null}
              </div>


            {/*  EDUCATION AND EMPLOYMENT  */}
            <div className="col-md-12 mb-3 border_total_sale mt-3">
              <label className="profileheddingcls profileheddingclsfont px-3 pt-3 pb-2">Education</label>
              <hr className="mt-0" />
              <div className='px-3 pb-3'>
                {EducationData.map((item, index) => (
                  <div className='row'>
                    <div className=' col-6 col-md-4 col-lg-3 mb-3'>
                      <label className="profileheddingcls profileheddingclsfont">Degree</label>
                      <div className='profiledetailscls'>{item.degree}</div></div>
                    <div className=' col-6 col-md-4 col-lg-3 mb-3'>
                      <label className="profileheddingcls profileheddingclsfont">School</label>
                      <div className='profiledetailscls'>{item.school}</div></div>

                    <div className=' col-6 col-md-4 col-lg-3 mb-3'>
                      <label className="profileheddingcls profileheddingclsfont">StudyArea</label>
                      <div className='profiledetailscls'>{item.studyArea}</div></div>


                    <div className=' col-12 col-md-4 col-lg-3 mb-3'>
                    <div className="d-flex gap-4">
                    <div>
                     <label className="profileheddingcls profileheddingclsfont">StartDate</label>
                     <div className='profiledetailscls'>{item.startDate}</div>
                     </div>
                      <div>
                      <label className="profileheddingcls profileheddingclsfont">EndDate</label>
                      <div className='profiledetailscls'>{item.endDate}</div>
                      </div>
                    </div>
                    </div>

                  
                    <div className='col-md-12 mb-3'>
                      <label className="profileheddingcls profileheddingclsfont">Description</label>
                      <div className='profiledetailscls'>{item.description}</div></div>
                  </div>
                ))}
              </div>
            </div>


            {/*  For Employeements */}
            <div className="col-md-12 mb-3 border_total_sale mt-3" >
              <label className="profileheddingcls profileheddingclsfont px-3 pt-3 pb-2">Employment </label><hr className="mt-0" />
              <div className=' px-3 pb-3'>
                {EmployeementData.map((item, index) => (
                  <div className='row'>
                    <div className='col-6 col-md-4 col-lg-3 mb-3'>
                      <label className="profileheddingcls profileheddingclsfont">Company</label>
                      <div className='profiledetailscls'>{item.company}</div></div>
                    <div className='col-6 col-md-4 col-lg-3 mb-3'>
                      <label className="profileheddingcls profileheddingclsfont">Title</label>
                      <div className='profiledetailscls'>{item.title}</div></div>


                    <div className='col-6 col-md-4 col-lg-3 mb-3'>
                      <label className="profileheddingcls profileheddingclsfont">StartDate</label>
                      <div className='profiledetailscls'>{item.startDate}</div></div>


                    <div className='col-6 col-md-4 col-lg-3 mb-3'>
                      <label className="profileheddingcls profileheddingclsfont">EndDate</label>
                      <div className='profiledetailscls'>{item.currentlyWorking ? "Working Here" : item.endDate}</div></div>
                    <div className='col-md-12 mb-3'>
                      <label className="profileheddingcls profileheddingclsfont">Description</label>
                      <div className='profiledetailscls'>{item.description}</div></div>
                  </div>
                ))}
              </div>
            </div>

            {/* Video Section */}
            <div className='col-md-12 mt-3 border_total_sale mt-3'>
              <label className="profileheddingcls profileheddingclsfont pt-3 px-3 pb-2">Video Introduction </label><hr  className="mt-0"/>
              {UserData.introLink != "" ? <div className="px-3">
                <label className='profileheddingcls profileheddingclsfont pb-2' >Videourl</label>
                <div className='profiledetailscls video-div'>
                  <iframe src={`${validateYouTubeUrl(UserData?.introLink || '')}`} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
              </div> : null}
              <hr  className=""/>
              <div className='mt-1 p-3' >
                <label className='profileheddingcls profileheddingclsfont' >Video</label>
                <div className='video-div'>{UserData.introVideo == "" ? null : <video  controls src={environment.api + "images/videos/" + UserData.introVideo} />}</div>
              </div>
            </div>

          </div>


          <div
            className="modal  fade"
            id="openmodal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header border-bottom mb-3">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Select the Job Post{" "}
                  </h5>
                  <button
                    type="button"
                    id="closeinviteModal"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <form onSubmit={(e) => HandleSubmit(e)}>
                  {" "}
                  <div className="modal-body">
                    <div className="form_group">
                      {ProjectData.length > 0 ? (
                        <>
                          <div className="select_itsmx">
                            {/* <select
                              value={SelectedProject}
                              disabled={MakeitDisable}
                              className="form-control myselect"
                              required
                              onChange={(e) => setSelectedProject(e.target.value)}
                            >
                              <option value="">Select a Job Post </option>
                              {ProjectData.map((item, index) => (
                                <option
                                  disabled={item.isJobInvited}
                                  value={item.id}
                                >
                                  {item?.name || item?.title}
                                  {item.isJobInvited ? " (Already Invited)" : ""}
                                </option>
                              ))}
                            </select> */}
                            <Select
                              placeholder="Invite"
                              className="text-capitalize dddskills w-100"
                              value={SelectedProject}
                              onChange={(e) => setSelectedProject(e)}
                              options={ProjectData}
                              isClearable
                              required
                            />
                          </div>

                          <div className="mt-3">
                            <label>Description</label>
                            <textarea
                              className="form-control"
                              value={Descriptiondata}
                              placeholder="Enter the Description here "
                              onChange={(e) =>
                                setDescriptiondata(e.target.value)
                              }
                            ></textarea>
                          </div>
                        </>
                      ) : (
                        <div className="text-center">
                          <button
                            className="btn btn-primary"
                            onClick={(e) => {
                              document
                                .getElementById("closeinviteModal")
                                .click();
                              Navigate.push(
                                `/addeditproject?comethrough=${id}`
                              );
                            }}
                          >
                            Add Job Post
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="modal-footer mt-3">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Invite
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        className="btn btn-primary d-none"
        data-toggle="modal"
        data-target="#opencont"
      >
        Launch demo modal
      </button>

      <div
        className="modal fade"
        id="opencont"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header ">
              <h3 className="modal-title">Create Offer</h3>

              {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                id="closecont"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {user?.id ? (
                <CreateContract
                  isTrue={false}
                  userid={id}
                  update={id}
                  HandleClose={HandleCloseModal}
                  chatwithId={id}
                  ComeFrom="mainhire"
                  hourlyRate={UserData.hourlyRate}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VirtualDetail;
