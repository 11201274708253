

export default function convertTimezone(dateTime, currentTimeZone, targetTimeZone) {

    const ConvertedData = new Date(dateTime).toLocaleString('en', { timeZone: targetTimeZone })
    // return ConvertedData;
    return dateTime
}


