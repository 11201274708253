import { useState, useEffect } from 'react';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import './style.scss';

const Skill = ({ setCount, setMyForm, MyForm }) => {
  const [State, setState] = useState("")
  const [Filter, setFilter] = useState({ search: "" })
  const [AllSkills, setAllSkills] = useState([]);
  const [Skills, setSkills] = useState([]);
  const [SelectSkills, setSelectSkills] = useState([]);
  const [SelectedSkillsName, setSelectSkillsName] = useState([])
  const GetSkills = (p = {}) => {

    setFilter({ search: "" })
    loader(true);
    const payload = MyForm?.skillType.map((itm) => itm?.id);
    ApiClient.get(`skills?status=active&skillType=${payload.toString()}`, p).then(res => {
      if (res.success) {
        const array = [];
        const data = res.data;
        const selectedarray = data.filter(value => MyForm?.skills?.includes(value.id));
        setSelectSkillsName(selectedarray?.map((item) => item?.name))
        setAllSkills(data);
        data.map((item, index) => {
          if (MyForm.skills.includes(item.id)) {
          }
          else {
            array.push(item);
          }
        })
        setSkills([...array]);
        setSelectSkills([...selectedarray]);
      }
      setTimeout(() => {
        loader(false);
      }, 200);
    })
  }

  useEffect(() => {
    GetSkills();
  }, [])

  const HandleAddSkills = (id) => {
    const Array = MyForm.skills;
    Array.push(id);
    setMyForm({ ...MyForm, skills: Array });
    GetSkills()

  }

  const RemoveSkills = async (id) => {
    const array = MyForm.skills;
    array.filter((item, index) => {
      if (item == id) {
        array.splice(index, 1);
      }
    })
    setMyForm({ ...MyForm, skills: array })
    GetSkills();
  }

  const HandleSearch = (value) => {
    setFilter({ search: value })
    ApiClient.get(`skills?search=${value}&status=active&skillType=${MyForm.skillType}`).then(res => {
      if (res.success) {
        const array = [];
        const data = res.data;
        data.map((item, index) => {
          if (MyForm.skills.includes(item.id)) {
          }
          else {
            array.push(item);
          }
        })
        setSkills([...array]);
      }
    })
  }
  const SkillHandler = (e) => {
    e.preventDefault();

    loader(true);
    ApiClient.post(`skill`, { name: Filter.search, skillType: MyForm?.skillType }).then(res => {
      if (res.success) {
        GetSkills();
      }
      loader(false);
    })
  }
  return (
    <>
      <div className="bg-white pt-4">
        <div className="container pl-5 pr-5 mt-5">
          <div className="row ">
            <div className="col-lg-6">
              <p>Job Post</p>
              <h2>What are the main skills required for your work?</h2>
            </div>
            <div className="col-lg-6">
              <h5 className='font-weight-bold mb-2'>Search or add up to 10 skills</h5>
              <div className='d-flex'>
                <input type='text' className="form-control" value={Filter.search} onChange={e => { e.target.value == "" ? GetSkills() : HandleSearch(e.target.value); }} />
                {/* {Skills.length == 0 ? <i className='fa fa-plus mt-2 ml-2' onClick={e => SkillHandler(e)}></i>:null} */}
              </div>
              <p className='text-primary mt-3'><i className='fa fa-star'></i> For the best result, add 3-5 skills </p>

              <h5 className="font-weight-bold mt-3">Popular Skills</h5>
              <div className='badges_skill'>
                {Skills.map((item, index) => (<span onClick={e => { MyForm.skills.length > 10 ? console.log("") : HandleAddSkills(item.id) }}>{item.name} <i className='fa fa-plus'  ></i></span>))}
              </div>
              {Skills.length == 0 ? <div className='text-center text-danger'>No SKill Exists</div> : null}
              <hr />
              <div className='badges_skill'>
                {SelectSkills.map((item, index) => (<span onClick={e => RemoveSkills(item.id)}>{item.name} <i className='fa fa-times'></i></span>))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_btn">
        <button className="btn btn-outline-primary" onClick={e => setCount(0)}>Back</button>
        <button className="btn btn-primary" disabled={MyForm.skills.length == 0} onClick={e => { setCount(2); setMyForm({ ...MyForm, skillsName: SelectedSkillsName }) }}  >Next: Scope</button>
      </div>

    </>
  );
};

export default Skill;





