import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { search_success } from '../../../actions/search';
import { logout } from '../../../actions/user';
import Html from './Html';
import environment from '../../../environment';
import HelpersHeader from './HelpersHeader';


const Header = () => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [isOpen1, setIsOpen1] = useState(false);
  const toggle1 = () => setIsOpen1(!isOpen1);
  const history = useHistory();
  const Virtual = environment.VirtualId;
  const employeerid = environment.EmployerId;
  const [role, setrole] = useState(employeerid);

  const searchState = useSelector((state) => state.search);

  const Logout = () => {
    dispatch(logout())

    localStorage.removeItem("persist:admin-app")
    localStorage.removeItem("token")
    history.push('/');
  };

  const user = useSelector((state) => state.user);

  useEffect(
    () => {
      window.scrollTo({ top: 0 });
      if (searchState.data) {
        dispatch(search_success(''))
      }

    },
    []
  );

  useEffect(() => {
    setSearch(searchState.data)
  }, [searchState])

  const [search, setSearch] = useState('')

  const searchHandle = (e) => {
    e.preventDefault()
    dispatch(search_success(search))
  }

  const searchChange = (e) => {
    setSearch(e)
    if (!e) {
      dispatch(search_success(''))
    }
  }


  const clear = () => {
    setSearch('')
    dispatch(search_success(''))
  }

  return (
    user.role && user?.role?.id == environment.AccountManagerId || user.role && user?.role?.id == environment.DirectManagerId ?
      <HelpersHeader
        isOpen={isOpen}
        toggle={toggle}
        searchHandle={searchHandle}
        search={search}
        user={user}
        searchChange={searchChange}
        isOpen1={isOpen1}
        clear={clear}
        setrole={setrole}
        Logout={Logout}
      />

      : <Html
        isOpen={isOpen}
        toggle={toggle}
        searchHandle={searchHandle}
        search={search}
        user={user}
        searchChange={searchChange}
        isOpen1={isOpen1}
        clear={clear}
        setrole={setrole}
        Logout={Logout}
      />
  );
};

export default Header;
