import { useEffect, useState } from "react";
import "./style.scss";
import { useSelector } from "react-redux";
import environment from "../../environment";
import { useHistory } from "react-router-dom";
import Header from "../../components/global/header";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const Html = ({
  tab,
  edit,
  ChangeStatus,
  statusChange,
  pageChange,
  deleteItem,
  filters,
  loaging,
  data,
  isAllow,
  searchHandle,
  searchChange,
  categoriesdata,
  search,
  CatType,
  totalBlog,
  total = { total },
}) => {
  const user = useSelector((state) => state.user);
  const Navigate = useHistory();
  const [Name1, setName1] = useState("");
  const [Email1, setEmail1] = useState("");
  const [Name2, setName2] = useState("");
  const [Email2, setEmail2] = useState("");
  const [Name3, setName3] = useState("");
  const [Email3, setEmail3] = useState("");
  const { type } = useParams();
  const TypeFinder = () => {
    if (type == "virtual") {
      return "Virtual Assistant";
    } else {
      return "Employer";
    }
  };

  const [RoleId, setRoleId] = useState("");
  useEffect(() => {
    if (type == "virtual") {
      setRoleId(environment.VirtualId);
    } else {
      setRoleId(environment.EmployerId);
    }
  });

  const HandleInviteSubmit = (e, data, type = "") => {
    e.preventDefault();
    loader(true);
    ApiClient.post(`referalSent`, {
      fullName: data.name,
      role: RoleId,
      email: data.email,
    }).then((res) => {
      if (res.success) {
        toast.success(`${TypeFinder()} Invited Successfully`);
        if (type == "one") {
          setName1("");
          setEmail1("");
        } else if (type == "two") {
          setName2("");
          setEmail2("");
        } else if (type == "three") {
          setName3("");
          setEmail3("");
        } else {
        }
      }
      loader(false);
    });
  };

  return (
    <>
      <Header />
      <div className="shadow_bx">
        <div className="container ">
          <div className="row mx-auto">
            <div className="col-md-10 mx-auto text-center mt-5">
              <h5 className="font-weight-bold mt-3 mb-5">
                Please enter your {TypeFinder()} name and email
              </h5>
              <form
                onSubmit={(e) =>
                  HandleInviteSubmit(e, { name: Name1, email: Email1 }, "one")
                }
              >
                <div className="card-box">
                  <div className="d-flex ">
                    <input
                      className="form-control mr-3"
                      value={Name1}
                      onChange={(e) => setName1(e.target.value)}
                      required
                      placeholder="Enter Name"
                    />
                    <input
                      className="form-control mr-3"
                      type="email"
                      value={Email1}
                      onChange={(e) => setEmail1(e.target.value)}
                      required
                      placeholder={`${TypeFinder()} Email`}
                    />
                    <button className="btn btn-primary" type="submit">
                      Invite
                    </button>
                  </div>
                </div>

              </form>
              <form
                onSubmit={(e) =>
                  HandleInviteSubmit(e, { name: Name2, email: Email2 }, "two")
                }
              >
                <div className="card-box">
                  <div className="d-flex ">
                    <input
                      className="form-control mr-3"
                      required
                      value={Name2}
                      onChange={(e) => setName2(e.target.value)}
                      placeholder="Enter Name"
                    />
                    <input
                      className="form-control mr-3"
                      type="email"
                      required
                      value={Email2}
                      onChange={(e) => setEmail2(e.target.value)}
                      placeholder={`${TypeFinder()} Email`}
                    />
                    <button type="submit" className="btn btn-primary">
                      Invite
                    </button>
                  </div>
                </div>

              </form>
              <form
                onSubmit={(e) =>
                  HandleInviteSubmit(e, { name: Name3, email: Email3 }, "three")
                }
              >
                <div className="card-box">
                  <div className="d-flex ">
                    <input
                      className="form-control mr-3"
                      value={Name3}
                      required
                      onChange={(e) => setName3(e.target.value)}
                      placeholder="Enter Name"
                    />
                    <input
                      className="form-control mr-3"
                      value={Email3}
                      required
                      type="email"
                      onChange={(e) => setEmail3(e.target.value)}
                      placeholder={`${TypeFinder()} Email`}
                    />
                    <button className="btn btn-primary" type="submit">
                      Invite
                    </button>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Html;
