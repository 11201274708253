import { useEffect, useState } from 'react'
import loader from '../../methods/loader';
import ApiClient from '../../methods/api/apiClient';
import moment from 'moment';
import methodModel from '../../methods/methods';
import Pagination from 'react-js-pagination';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import environment from '../../environment';
import convertTimezone from '../../components/common/TimeZoneConvertor/TimeZoneConvert';
import DatePickers from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import datepipeModel from '../../models/datepipemodel';

export default function DirectRequestEdtor({ contractId = null, contractData = null }) {
  const user = useSelector(state => state.user);
  const [BillingFilters, setBillingFilters] = useState({
    addedBy: "",
    contractId: contractId ? contractId : "",
    page: 1,
    count: 10
  });
  const [editedStartTime, setEditedStartTime] = useState("");
  const [editedEndTime, setEditedEndTime] = useState("");
  const [BillingData, setBillingData] = useState([]);

  const [TotalLength, setTotalLength] = useState(0);
  const [editableItems, setEditableItems] = useState([]);
  const GetBillingData = (p = {}) => {
    loader(true);
    const newfilters = { ...BillingFilters, ...p };
    ApiClient.get(`${environment.VirtualId == user?.role?.id ? 'billing-list/virtual' : 'billing/list'}`, newfilters).then((res) => {
      if (res.success) {
        setTotalLength(res.total);
        setBillingData(res.data?.map((item) => {
          return ({ ...item, start: datepipeModel.ReverseTimetoObj(item?.startTime), end: item?.endTime ? datepipeModel.ReverseTimetoObj(item?.endTime) : "" })
        }));
      }
      loader(false);
    });
  };

  const [ChangedData, setChangedData] = useState([]);
  const handleSave = (index) => {
    setEditableItems((prev) => prev.filter((item) => item !== index));
    const existingIndex = ChangedData.findIndex(
      (item) => item.id === BillingData[index].id
    );

    // If not, push the changed data to the new array
    if (existingIndex === -1) {
      setChangedData((prev) => [...prev, BillingData[index]]);
    } else {
      // If exists, update the data in the array
      setChangedData((prev) => {
        const updatedData = [...prev];
        updatedData[existingIndex] = BillingData[index];
        return updatedData;
      });
    }
    const data = BillingData[index];
    const isoStartDate = datepipeModel.TimeConvertToTimezone(data.start.toString())
    const isoEndDate = datepipeModel.TimeConvertToTimezone(data.end.toString())

    const Payload = {
      "data": [
        {
          "id": data?.id,
          "memo": data.memo,
          "startTime": isoStartDate,
          "endTime": isoEndDate
        }
      ]
    }
    ApiClient.put(`updateBilling`, Payload).then((res) => {
      if (res.success) {
        GetBillingData()
        toast.success(`Entry updated successfully`);
      }
    });
  };

  const handleChangeStartTime = (index, value) => {
    setBillingData((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], start: value };
      return newData;
    });
  };

  const handleChangeEndTime = (index, value) => {
    setBillingData((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], end: value };
      return newData;
    });
  };


  const handleEdit = (index) => {
    setEditedStartTime(BillingData[index].startTime);
    setEditedEndTime(BillingData[index].endTime || "");
    setEditableItems((prev) => [...prev, index]);
  };

  const [ContractsFilter, setContractsFilter] = useState({ status: "accepted", directmanagerId: user.id, search: "" })
  const [AllContracts, setAllContracts] = useState([]);
  //  Getting all the Contracts 
  const GetAllContracts = () => {
    loader(true);
    ApiClient.get(`contracts`, ContractsFilter).then(res => {
      if (res.success) {
        setAllContracts(res?.data);
        setBillingFilters({ ...BillingFilters, contractId: res.data[0]?.id });
        GetBillingData({ contractId: res?.data[0]?.id })
      }
      loader(false);
    })
  }
  useEffect(() => {
    if (contractId == null) {
      GetAllContracts()
    }
    else {
      setBillingFilters({ ...BillingFilters, contractId: contractId })
      GetBillingData({ contractId: contractId })
    }
  }, [])

  const TimeZoneHandler = (key) => {
    if (contractData) {
      if (user.role?.id == environment.EmployerId) {
        let timeData = convertTimezone(key, contractData?.timeZone?.value, user?.globallyTimeZone?.value)
        return timeData
      }
    } else {
      return key
    }
  }
  return (
    <div className='mt-4'>
      {!contractId ? <h4>Clock In and Out Entries</h4> : null}
      {!contractId ? <div className=' col-md-4 mt-2'>
        <label>Contract</label>
        <select className='form-control' value={BillingFilters.contractId} onChange={e => { setBillingFilters({ ...BillingFilters, contractId: e.target.value }); }} placeholder='Selected Contract'>
          <option disabled>Please Select Contract</option>
          {AllContracts.map((item, index) => (
            <option value={item.id}>{item?.project_details?.name || item?.contractName}</option>
          ))}
        </select>
      </div> : null}
      <table className="table table-striped">
        <thead className="table_head">
          <tr className="heading_row">
            <th scope="col" className="table_data">
              Memo
            </th>
            <th scope="col" className="table_data">
              Start Date & Time
            </th>
            <th scope="col" className="table_data">
              End Date & Time
            </th>
          </tr>
        </thead>
        <tbody>
          {BillingData.map((item, index) => (
            <tr className="data_row" key={index}>
              <td className="table_dats">{item.memo}</td>
              <td className="table_dats  date_dats">
                {editableItems.includes(index) ? (
                  <DatePickers
                    className="form-control"
                    selected={item.start}
                    onChange={(date) => handleChangeStartTime(index, date)}
                    showTimeSelect
                    dateFormat="dd MMM, yyyy hh:mm a"
                    placeholderText="Start Date & Time"
                  />
                  // <input
                  //   type="datetime-local "
                  //   value={moment(TimeZoneHandler(item.startTime)).format(
                  //     "YYYY-MM-DDThh:mm"
                  //   )}
                  //   onChange={(e) =>
                  //     handleChangeStartTime(
                  //       index,
                  //       e.target.value
                  //     )
                  //   }
                  // />
                ) : (
                  <>
                    {methodModel.convertDate(item?.startTime, "DD MMM, YYYY")}
                    {" "}
                    {methodModel.ConvertTime(item?.startTime)}
                  </>
                )}
              </td>
              <td className="table_dats date_dats">
                {editableItems.includes(index) ? (
                  <DatePickers
                    className="form-control"
                    selected={item.end || null}
                    onChange={(date) => handleChangeEndTime(index, date)}
                    showTimeSelect
                    minDate={new Date(TimeZoneHandler(item.startTime))}
                    dateFormat="dd MMM, yyyy hh:mm a"
                    placeholderText="Start Date & Time"
                  />
                  // <input
                  //   type="datetime-local"
                  //   value={item.endTime ? moment(TimeZoneHandler(item.endTime)).format(
                  //     "YYYY-MM-DDThh:mm"
                  //   ) : "---"}
                  //   min={moment(TimeZoneHandler(item.startTime)).format("YYYY-MM-DDThh:mm")}
                  //   onChange={(e) =>
                  //     handleChangeEndTime(
                  //       index,
                  //       e.target.value
                  //     )
                  //   }
                  // />
                ) : !item.endTime ? "---" : (
                  <>
                    {methodModel.convertDate(item?.endTime, "DD MMM, YYYY")}
                    {" "}
                    {methodModel.ConvertTime(item?.endTime)}
                  </>
                )}
              </td>
              <td>
                {item?.billingSchedule?.status ? item?.billingSchedule?.status == "pending" ? "Requested" : item?.billingSchedule?.status == "accepted" ? "Approved" : "Declined" :
                  <>
                    {
                      editableItems.includes(index) ? (
                        <>
                          {ChangedData.length == 0 ? (
                            <i
                              className="fa fa-check text-success"
                              onClick={() => handleSave(index)}
                            ></i>
                          ) : null}
                        </>
                      ) : ChangedData.length == 0 ? (
                        methodModel.HoursCalculatorFromNow(
                          item.startDate
                        ) >= 24 ? null : (
                          <i
                            className="fa fa-pen text-info"
                            onClick={() => handleEdit(index)}
                          ></i>
                        )
                      ) : null}
                  </>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {BillingData.length == 0 ? <div className='text-center text-danger'>No Entries here</div> : null}
      <div className='float-end'>
        {BillingData.length < TotalLength ? <Pagination
          activePage={BillingFilters.page}
          onChange={e => { setBillingFilters({ ...BillingFilters, page: e }); GetBillingData({ page: e }) }}
          totalItemsCount={TotalLength}
        /> : null}
      </div>
    </div>
  )
}
