/*
 * @Author: Ghost 
 * @Email: Ghost@yopmail.com
 * @Date: 2024-02-13 11:30:23 
 * @Last Modified by: Someone
 * Email: anilgodara4239@gmail.com
 * @Last Modified time: 2024-08-21 20:29:48
 * */
import { useEffect, useState } from 'react'
import Header from '../../components/global/header'
import ApiClient from '../../methods/api/apiClient'
import loader from '../../methods/loader';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from 'antd';
import axios from 'axios';
import environment from '../../environment';
import dayjs from 'dayjs';
import methodModel from '../../methods/methods';
const { RangePicker } = DatePicker;
export default function VirtualReport() {
    // Get today's date
    const today = new Date();
    // Get tomorrow's date
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() - 1);
    const user = useSelector(state => state.user);
    const [filters, setfilters] = useState({ name: "", start_date: moment(tomorrow).format('YYYY-MM-DD'), end_date: moment(new Date()).format("YYYY-MM-DD"), employerId: "", contractId: "", virtualId: user.id, search: "" })
    const Navigate = useHistory();
    const [VAList, setVAList] = useState([]);
    const [Data, setData] = useState([]);

    const GetReports = (p = {}) => {
        const NewFilters = { ...filters, ...p, virtualId: user.id }
        delete NewFilters.name
        loader(true);
        ApiClient.get(`virtualReport/list`, NewFilters).then(res => {
            if (res.success) {
                setData(res?.data);
            }
            loader(false);
        })
    }

    const GetVALIST = () => {
        loader(true);
        ApiClient.get(`contracts?search=&status=accepted&addedBy=${user.id}`).then(async (res) => {
            if (res.success) {
                const newdata = res.data;
                const updateddata = []
                await newdata.map((item, index) => {
                    //  We Need to make the per Virtual Assistant id unique so we need to do this
                    updateddata.push({ id: item?.contractFrom_details?._id, fullName: item?.project_details?.title || item?.project_details?.name, contract_Id: item.id, mainid: item?.contractFrom_details?._id + `12${index}`, VAName: item?.contractFrom_details.fullName })
                }
                );
                setVAList(updateddata)
            }
            loader(false)
        })
    }
    useEffect(() => {
        GetVALIST()
        GetReports()
    }, [])

    const [SelectedDateRange, setSelectedDateRange] = useState([dayjs(tomorrow), dayjs(today)])

    const handleDateChange = (date, dateStrings) => {
        setSelectedDateRange(date);
        let dates = dateStrings;
        setfilters({ ...filters, start_date: dates[0] || "", end_date: dates[1] || "" })
        GetReports({ start_date: dates[0] || "", end_date: dates[1] || "" })
        // You can perform additional filtering or other actions based on the selected date range
    };
    const DownloadVirtualReport = async (e) => {
        e.preventDefault();
        const token = await localStorage.getItem("token");
        const req = await axios({
            method: "get",
            url: `${environment.api}exportVirtualReport?virtualId=${user.id}&start_date=${filters?.start_date}&end_date=${filters?.end_date}&employerId=${filters?.employerId ? filters?.employerId : ""}&contractId=${filters?.contractId}&search=${filters?.search}`,
            responseType: "blob",
            body: { token: token }
        });
        var blob = new Blob([req.data], {
            type: req.headers["content-type"],
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Time log report_VA.xlsx`;
        link.click();


    }

    const DownloadPdf = async (e) => {
        e.preventDefault();
        loader(true)
        const token = await localStorage.getItem("token");
        const req = await axios({
            method: "get",
            url: `${environment.api}virtual-report/pdf?start_date=${filters?.start_date}&end_date=${filters?.end_date}&employerId=${filters?.employerId ? filters?.employerId : ""}&virtualId=${user?.id ? user?.id : ""}&contractId=${filters?.contractId}&search=${filters?.search}`,
            responseType: "blob",
            body: { token: token },
        });
        var blob = new Blob([req.data], {
            type: req.headers["content-type"],
        });
        const blobUrl = URL.createObjectURL(blob);
        setTimeout(() => {
            fetch(blobUrl)
                .then((res) => res.json())
                .then(async (jsonData) => {
                    const pdfUrl = jsonData.path;
                    if (pdfUrl) {
                        await fetch(`${environment.api}${pdfUrl}`)
                            .then((res) => res.blob())
                            .then((pdfBlob) => {
                                const a = document.createElement("a");
                                a.href = URL.createObjectURL(
                                    new Blob([pdfBlob], { type: "application/pdf" })
                                );
                                a.download = "Time log report_VA.pdf";
                                a.click();
                                loader(false);
                            })
                    } else {
                        loader(false);
                    }
                })
        }, 3000);
        loader(false)
    };

    // Search functions
    const searchHandle = (e) => {
        e.preventDefault()
        GetReports({ search: filters?.search })
    }
    const clear = () => {
        setfilters({ ...filters, search: "" })
        GetReports({ search: "" })
    }
    const hanldeSearch = (data) => {
        setfilters({ ...filters, search: data })
        if (!data) {
            GetReports()
        }
    }

    return (
        <>
            <Header />
            <div className='container mt-5 '>
                <div className='d-flex justify-content-between mb-4'>
                    <div className=''>
                        <h3 className='mb-0' > Reports</h3>
                    </div>
                    <div className='d-flex align-items-center gap-3' >
                        <div className=''>
                            {/* <label className='mt-2 ml-1'>Search </label> */}
                            <form className='headerSearch' onSubmit={searchHandle}>
                                <input type="text" placeholder="Search..." name='search' value={filters?.search} onChange={e => hanldeSearch(e.target.value)} className="Searchbar"></input>
                                <i className="fa fa-search" onClick={searchHandle} aria-hidden="true"></i>
                                {filters?.search != "" ? <i className="fa fa-times" onClick={clear} aria-hidden="true"></i> : ""}
                                <button type='submit' className='d-none'>Search</button>
                            </form>
                        </div>
                        <button className='btn btn-primary' onClick={e => DownloadVirtualReport(e)}>Export</button>

                        <button className='btn btn-primary' onClick={e => DownloadPdf(e)}>Pdf Export</button>

                    </div>
                </div>
                <div className='row'>

                    <div className='col-lg-3 col-md-5'>
                        <label className='mt-2'>Date Filters </label>
                        <RangePicker
                            className="dateRangePicker"
                            placeholder={["Start Date", "End Date"]}
                            format="YYYY-MM-DD"
                            onChange={handleDateChange}
                            // onBlur={e=>setblur(true)} 
                            value={SelectedDateRange}
                        />
                    </div>
                    <div className='col-lg-5 col-md-7 '>
                        <div className='mt-2'>  <label>Employer Name </label>

                        </div>

                        <div className='d-flex gap-3 align-items-center'>
                            <div className="dropdown">
                                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {filters.employerId == "" ? "Select Employer" : filters.name}
                                </button>
                                <ul className="dropdown-menu">
                                    {VAList.map((item) => (<li><a className="dropdown-item" onClick={e => { setfilters(prev => ({ ...filters, name: item?.VAName, employerId: item?.id, contractId: item?.contract_Id })); GetReports({ name: item?.VAName, employerId: item?.id, contractId: item?.contract_Id }) }}>{item?.VAName}({item?.fullName})</a></li>))}

                                </ul>
                            </div>
                            <div className='ms-2'>
                                {filters.employerId != "" || filters.start_date || filters.end_date || filters?.search ? <button className='btn btn-primary ' onClick={() => { setfilters({ employerId: "", end_date: "", start_date: "", contractId: "", name: "", search: "", contractId: "" }); setSelectedDateRange(null); GetReports({ employerId: "", end_date: "", start_date: "", contractId: "", name: "", search: "", contractId: "" }) }}>Clear</button> : null}
                            </div>
                        </div>

                    </div>
                </div>


                <div className="mt-4 main-table table-bordered pb-4">

                    <div className='cmc_table '>

                        <div className='row cms firstcms table-text'>
                            <div className='col-3 border-color'>
                                <label>Contract Name</label>
                            </div>
                            <div className='col-3 border-color'>
                                <label>Employer Name</label>
                            </div>

                            <div className='col-3 border-color'>
                                <label>Total Hours</label>
                            </div>
                            <div className='col-3 border-color'>
                                <label>Total Amount</label>
                            </div>


                        </div>

                        {Data.map((item, index) => (
                            <div className='row cms'>
                                <div className='col-3 '>
                                    <p className='pms npms text-info report_cardss' style={{ cursor: "pointer" }} >{item?.contractName || item?.projectsDetail?.name}</p>
                                </div>
                                <div className='col-3 '>
                                    <p className='pms npms text-info report_cardss' style={{ cursor: "pointer" }} >{item?.employerDetail?.fullName} </p>
                                </div>


                                <div className='col-3'>
                                    <b style={{ fontWeight: "300" }} className='text-black'><p className='pms gms report_cardss'>{item?.totalDurationOfTime_hour < 10 ? "0" + item?.totalDurationOfTime_hour : item?.totalDurationOfTime_hour} : {item?.totalDurationOfTime_min < 10 ? "0" + item?.totalDurationOfTime_min : item?.totalDurationOfTime_min} hrs</p></b>
                                </div>
                                <div className='col-3'>
                                    <p className='pms gms report_cardss'>{parseFloat(item?.TotalVirtualHourlyRate || 0).toFixed(2)}USD</p>
                                </div>
                                {item?.billings_details && item?.billings_details[0] && item?.billings_details?.map((itm) => (
                                    <>
                                        <div className='col-3 ' >
                                            {itm?.memo.slice(0, 100)}
                                        </div>
                                        <div className='col-3 '>
                                            <p className='pms  report_cardss'> {methodModel.convertDate(itm?.startTime)}{" "}{methodModel.ConvertTime(itm?.startTime)} </p>
                                        </div>

                                        <div className='col-3 ' >
                                            <p className='pms report_cardss '> {methodModel.convertDate(itm?.endTime)}{" "}{methodModel.ConvertTime(itm?.endTime)} </p>
                                        </div>

                                        <div className='col-2 ' >
                                            {/* <p className='pms '>{parseFloat(itm?.totalTime).toFixed(2)} hrs</p> */}
                                        </div>
                                        <div className='col-1 ' >

                                        </div>

                                    </>
                                ))}
                            </div>
                        ))}

                    </div>
                    {Data.length == 0 ? <div className='text-danger text-center'>No Active contract to view the Reports.</div> : null}

                </div>
            </div>
        </>
    )
}